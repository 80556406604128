import Debug from '@/components/Debug'
import { mapGetters } from 'vuex'
import { capitalize } from '@/utils'

export default {
  components: {Debug},
  data() {
    return {
      loading: true,
      resource: this.$route.meta.resource,
      item: null,
      parent: null,
      meta: null
    }
  },
  watch: {
    async $route() {
      await this.fetch()
    }
  },
  async created() {
    await this.fetch()

    this.$root.$on('item:refresh',this.fetch)
  },
  beforeDestroy(){
    this.$root.$off('item:refresh',this.fetch)
  },
  computed: {
    ...mapGetters({
      userIsSuperAdmin: 'auth/isSuperAdmin',
      userIsAdmin: 'auth/isAdmin'
    }),
  },
  methods: {
    fetched(){},
    async fetch() {
      this.loading = true
      try {
        this.item = await this.resource.get(this.$route.params.id)

        if(this.resource.parent) {
          if(this.item[`$${this.resource.parent.name}`]) {
            this.parent = this.item[`$${this.resource.parent.name}`]
          } else if(this.$route.params.parent_id) {
            this.parent = await this.resource.parent.get(this.$route.params.parent_id)
          }
        }

        this.fetched()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async action(action) {
      if(!this.item.$can(action)) console.error('Invalid action',action)

      if(action === 'delete') {
        let confirm = await this.$bvModal.msgBoxConfirm(this.$t('item.confirm-delete',{item: this.$t(`${this.resource.plural}.singular`)}),{
          cancelTitle: this.$t('common.cancel'),
          okTitle: this.$t('item.delete'),
          okVariant: 'danger'
        })

        if(confirm) {
          await this.item.$delete()
          this.$router.replace({name: `${this.resource.plural}`})
        }
      }
    }
  },
  metaInfo() {
    let resource = capitalize(this.$t(`${this.resource.plural}.singular`))
    let title = this.item?.$meta?.title

    return {
      title: `${resource}: ${title}`,
    };
  },
};