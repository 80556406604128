<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "App",
  metaInfo() {
    let titleTemplate
    if(process.env.NODE_ENV === 'production') {
      titleTemplate = 'AeroCollect · %s'
    } else {
      titleTemplate = `[AeroCollect ${process.env.NODE_ENV}] · %s`
    }

    return {
      titleTemplate
    }
  }
}
</script>

<style>
</style>
