<script>
export default {
  created: function() {
    this.$on('bv::modal::hide',(e,modalId) => {
      if(modalId === this.$refs.modal.localId_) {
        this.resolve(false)
        this.$destroy()
      }
    })

    this.$nextTick(function() {
      let element = document.createElement('div')
      this.$mount(element)
      this.$refs.modal.show()
    })
  },
  methods: {
    close(response = true){
      this.resolve(response)
      this.$refs.modal.hide()
    }
  }
}
</script>