<template>
  <b-button :disabled="running" v-if="action.static || item.$can(action.name)" :variant="action.variant" @click="performAction">
    <i class='bx' :class="{[action.icon]: true, 'bx-flashing': running}" ></i>
    {{ $t(action.label || `${resource.plural}.actions.${action.name}`) }}
  </b-button>
</template>

<script>
import Resource from '@/services/Resource'
export default {
  props: {
    resource: Function,
    item: Resource,
    action: Object
  },
  data() {
    return {
      running: false,
    }
  },
  methods: {
    async performAction() {
      this.running = true
      try {
        if(this.action.modal) {
          let result = await this.$modal(this.action.modal,{
            resource: this.resource,
            item: this.item
          })
          
          result && this.$root.$emit('item:refresh')
        } else {
          let confirm = true
          
          if(this.action.confirm) {
            confirm = await this.$bvModal.msgBoxConfirm(this.$t(this.action.confirmText),{
              autoFocusButton: this.action.confirmAutoFocus ||'ok',
              cancelTitle: this.$t(this.action.confirmCancel || 'common.cancel'),
              okTitle: this.$t(this.action.confirmOk || 'common.ok'),
              okVariant: this.action.confirmVariant || 'primary',
            })
          }
  
          if(confirm) {
            let {refresh} = await this.action.action(this.item)
            refresh && this.$root.$emit('item:refresh')
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.running = false
      }
    }
  }
}
</script>