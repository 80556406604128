import api from "@/api";
import i18n, { availableLocales } from "@/i18n";
import Resource from "@/services/Resource";
import store from "@/store";
import { download } from "@/utils";
import Export from '@/components/Modals/Export'
import Vue from "vue";
import {deleteBatchAction, nameField } from "./common";

export const STATUS_CUSTOMER_CANCELLED = -100
export const STATUS_EXPIRED            = -50
export const STATUS_DRAFT              = 0
export const STATUS_AWAITING_RESPONSE  = 20
export const STATUS_CUSTOMER_PAID      = 80
export const STATUS_COMPLETED          = 100

export const exportFormats = [
  {
    code: 'XLSX',
    label: 'Microsoft Excel Spreadsheet (XLSX)'
  },
  {
    code: 'CSV',
    label: 'Comma Separated Values (CSV)'
  },
]

export default class Order extends Resource {
  static name = 'order'
  static icon = 'bx-money'

  static relations = [
    {
      name: 'order_lines',
      resource: 'order_lines'
    }
  ]

  static get defaultTableState() {
    return {
      ...super.defaultTableState,
      sortBy: "updated_at",
      sortDesc: true,
    }
  }

  static fields = [
    { 
      key: 'order_number',
      sortable: true,
      label: 'orders.order_number',
    },
    nameField('last_name'),
    {
      key: 'status',
      label: 'common.status',
      sortable: true,
    },
    {
      key: 'amount',
      label: 'orders.amount',
      sortable: true,
      formatter: (v,k,item) => new Intl.NumberFormat(i18n.locale, { 
        style: 'currency', 
        currency: item.currency, 
      }).format(v/100)
    },
    { 
      key: 'locale', 
      label: 'common.locale',
      sortable: true,
      formatter: v => i18n.t(`locale.${v}`)
    },
    { 
      key: 'currency', 
      label: 'common.currency',
      sortable: true,
    },
    { 
      key: 'created_at', 
      sortable: true,
      label: 'common.created_at',
      formatter: (value) => Vue.filter('moment')(value)
    },
    { 
      key: 'updated_at', 
      sortable: true,
      label: 'common.updated_at',
      formatter: (value) => Vue.filter('moment')(value)
    },
    { 
      key: 'created_by_name', 
      sortable: true,
      sortKey: 'created_at',
      label: 'orders.created_by',
    },
  ]
  
  static filters = [
    {
      type: 'select',
      name: 'status',
      label: 'orders.status',
      options: this.statuses.filter(s => !s.hidden),
      multiple:  true,
    },
    {
      type: 'date',
      name: 'created_at',
      label: 'common.created_at',
    },
    {
      type: 'relation',
      name: 'createdBy',
      label: 'orders.created_by',
      resource: 'users'
    },
    {
      type: 'select',
      name: 'locale',
      label: 'common.locale',
      options: availableLocales,
    },
    {
      type: 'select',
      name: 'currency',
      label: 'common.currency',
      options: this.currencies,
    },
  ]

  static actions = [
    {
      name: 'send',
      icon: 'bx-mail-send',
      variant: 'success',
      async action(item) {
        await item.$send()

        return {refresh: true}
      }
    },
    {
      name: 'resend',
      icon: 'bx-mail-send',
      // variant: 'success',
      async action(item) {
        await item.$resend()

        return {refresh: true}
      }
    },
    {
      name: 'complete',
      icon: 'bx-check-circle',
      variant: 'success',
      async action(item) {
        await item.$markAsCompleted()

        return {refresh: true}
      }
    },
  ]

  static batchActions = [
    deleteBatchAction(),
    {
      name: 'complete',
      icon: 'bx-check-circle',
      label: 'orders.batch-actions.mark-as-completed',
      variant: 'success',
      confirm: true,
      async action(item) {
        return await item.$markAsCompleted()
      }
    },
    {
      name: 'export',
      icon: 'bx-export',
      label: 'orders.batch-actions.export',
      modal: Export,
    },
  ]

  async $send() {
    await api.post(`orders/${this.id}/send`)
  }

  async $resend() {
    await api.post(`orders/${this.id}/resend`)
  }

  async $markAsCompleted() {
    await api.post(`orders/${this.id}/complete`)
  }

  static async export(ids,format = 'XLSX') {
    let response = await api.post('orders/export',{ids,format},{
      responseType: 'blob'
    })

    download(response)

    return true
  }

  get name() {
    return `${this.first_name} ${this.last_name}`
  }

  get defaults() {
    return {
      country: store.state.defaultCountry,
      locale: store.state.defaultLocale,
      currency: 'USD',
      product_diff: 0,
      shipping_diff: 0,
      duties_diff: 0,
      variables: {}
    }
  }

  static get locales() {
    return availableLocales
  }

  static get currencies() {
    return ['USD'].map(c => ({label: c, code: c}))
  }

  static diffs = [
    {
      field: 'product_diff',
      label: 'order_lines.product_diff',
      icon: 'bx-package'
    },
    {
      field: 'shipping_diff',
      label: 'order_lines.shipping_diff',
      icon: 'bxs-truck'
    },
    {
      field: 'duties_diff',
      label: 'order_lines.duties_diff',
      icon: 'bx-coin'
    },
  ]

  static get statuses() {
    return [
      { label: `orders.statuses.${STATUS_DRAFT}`, code: STATUS_DRAFT, icon: 'bx-file-blank' },
      { label: `orders.statuses.${STATUS_AWAITING_RESPONSE}`, code: STATUS_AWAITING_RESPONSE, icon: 'bx-send' },
      { label: `orders.statuses.${STATUS_CUSTOMER_PAID}`, code: STATUS_CUSTOMER_PAID, icon: 'bx-user-check' },
      { label: `orders.statuses.${STATUS_COMPLETED}`, code: STATUS_COMPLETED, icon: 'bx-check-circle' },
      { label: `orders.statuses.${STATUS_EXPIRED}`, code: STATUS_EXPIRED, variant: 'danger', icon: 'bx-error-circle' },
      { label: `orders.statuses.${STATUS_CUSTOMER_CANCELLED}`, code: STATUS_CUSTOMER_CANCELLED, variant: 'danger', icon: 'bx-x-circle' },
    ]
  }

}

Resource.registerResource(Order)