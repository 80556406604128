<template>
  <MainLayout fluid>
      <TemplatesTable />
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemTableTemplate from '@/components/ItemTableTemplate'
import ItemTable from '@/components/ItemTable'
import Template from '@/resources/Template'

const TemplatesTable = {
  ...ItemTableTemplate,
  mixins: [ItemTable(Template)]
}

export default {
  components: { MainLayout, TemplatesTable },
  metaInfo() {
    return {
      title: this.$t('templates.title'),
    }
  },
};
</script>