<template>
  <b-card>
    <template #header> 
      <b-button 
        class="float-right"
        variant=""
        @click="reversed = !reversed"
      >
        <i class='bx' :class="{'bx-sort-up': reversed, 'bx-sort-down': !reversed}" ></i>
      </b-button>
      <i class='bx bx-list-ul' />
      {{$t('common.activity')}}
    </template>
    <div v-if="events">
      <dl>
        <value 
          v-for="(event,i) in sortedEvents" 
          :key="i" 
          :raw-label="event.date | moment('LLLL')" 
          :icon="getIcon(event)"
          :value="true"
        >
          <template v-if="event.type === 'status'">
            {{$t(`common.status`)}}: <status v-bind="{item}" :value="event.data" />
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span>
          </template>
          <template v-else-if="event.type === 'notification-sms'">
            {{$t(`events.notification-sms`)}}
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span><br>
            <span class="text-initial">{{$t(`${resource.plural}.events.notification.${event.data}`)}}</span>
          </template>
          <template v-else-if="event.type === 'notification-email'">
            {{$t(`events.notification-email`)}}
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span><br>
            <span class="text-initial">{{$t(`${resource.plural}.events.notification.${event.data}`)}}</span>
          </template>
          <template v-else-if="event.type === 'notification-failed'">
            <span class="text-danger">{{$t(`events.notification-failed`)}} </span>
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span><br>
            <span class="text-initial">{{event.data}}</span>
          </template>
          <template v-else-if="event.type === 'notification-failed-sms'">
            <span class="text-danger">{{$t(`events.notification-failed-sms`)}} </span>
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span><br>
            <span class="text-initial">{{event.data}}</span>
          </template>
          <template v-else-if="event.type === 'notification-failed-email'">
            <span class="text-danger">{{$t(`events.notification-failed-email`)}} </span>
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span><br>
            <span class="text-initial">{{event.data}}</span>
          </template>
          <template v-else>
            {{$te(`${resource.plural}.events.${event.type}`) ? $t(`${resource.plural}.events.${event.type}`,event) : event.type}}
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span>
            <div v-if="event.data" class="text-initial">{{event.data}}</div>
          </template>
        </value>
      </dl>
      <span v-if="item.updated_at" class="small muted">
        {{$t('common.updated_at')}}:
        {{item.updated_at | moment('LLLL')}}
      </span>
    </div>
    <dl v-else>
      <value label="common.created_at" icon="bxs-circle" :value="item.created_at | moment('LLLL')" />
      <value label="common.updated_at" icon="bxs-circle" :value="item.updated_at | moment('LLLL')" />
    </dl>
    <slot />
  </b-card>
</template>

<script>
import Value from "@/components/Value";
import Status from "@/components/Status";
import Resource from '@/services/Resource';

export default {
  components: {Value,Status},
  props: {
    item: {
      type: Resource,
      required: true
    }
  },
  computed: {
    resource() {
      return this.item.constructor
    },
    events() {
      if('events' in this.item.$meta) {
        return this.item.$meta.events
      } else {
        return null
      }
    },
    sortedEvents() {
      return this.reversed ? Object.assign([], this.events).reverse() : this.events
    },
    reversed: {
      get () {
        return this.$store.state.activitiesReversed
      },
      set (reversed) {
        this.$store.commit(`setActivitiesReversed`, reversed)
      },
    },
  },
  methods: {
    getIcon(event)  {
      switch(event.type) {
        case 'created':
          return 'bxs-star'
        case 'status':
          return 'bxs-analyse'
        case 'bringoz':
          return 'bxs-truck'
        case 'notification':
          return 'bxs-notification'
        case 'notification-sms':
          return 'bxs-message-rounded-detail'
        case 'notification-email':
          return 'bxs-envelope'
        case 'responded':
          return 'bxs-edit-alt'
        case 'notification-failed-email':
          return 'bx-envelope text-danger'
        case 'notification-failed-sms':
          return 'bx-message-rounded-detail text-danger'
        case 'notification-failed':
          return 'bx-error text-danger'
        case 'rerouted':
          return 'bxs-direction-right'
        case 'replaced':
        case 'replaces':
          return 'bx-repost'
        default:
          return 'bxs-circle'
      }
    }
  }
}
</script>