<template>
  <div>
    <div :class="['env-disclaimer',env]" v-if="env !== 'production'">[{{env}}]</div>
    <Sidebar />
    <div class="main-content">
      <div :class="[fluid ? 'container-fluid' : 'container', 'my-4', 'px-4']">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
export default {
  components: { Sidebar },
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    }
  }
};
</script>