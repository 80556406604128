<template>
  <b-modal 
    ref="modal" 
    :no-close-on-backdrop="processing"
    :no-close-on-esc="processing"
    :hide-header-close="processing"
    :hide-footer="processing"
  >
    <template #modal-title>
      <template v-if="processing">
        {{$t(`actions.${action.name}.processing`,{items: $tc(`${resource.plural}.pluralize`,items.length)})}}
      </template>
      <template v-else>
        {{$t(`actions.${action.name}.select-format`)}}
      </template>
    </template>

    <div v-if="processing" class="text-center">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <div v-else>
      <b-form-group :label="$t('actions.export.format')">
        <b-form-radio v-for="(exportFormat,i) in exportFormats" :key="i" v-model="format" :value="exportFormat.code">{{exportFormat.label}}</b-form-radio>
      </b-form-group>
    </div>

    <template #modal-footer>
      <b-button @click="close(false)">
        {{$t('common.cancel')}}
      </b-button>
      <b-button @click="run" variant="primary">
        <i class='bx bx-export' ></i>
        {{$t('actions.export.export')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import Order, { exportFormats } from '@/resources/Order'
import { mapGetters } from 'vuex'

export default {
  name: 'Export',
  extends: Modal,
  data() {
    return {
      processing: false,
      format: exportFormats[0].code,
      exportFormats
    }
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    resource: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  created() {
    this.format = this.user.preferences?.default_export_format || this.format
  },
  methods: {
    async run() {
      this.processing = true
      await Order.export(this.items.map(item => item.id),this.format)
      this.close(true)
    }
  }
}
</script>