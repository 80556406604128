import Resource from "@/services/Resource";

export default class OrderLine extends Resource {
  static name = 'order_line'
  static icon = 'bx-package'

  get defaults() {
    return {
      quantity: 1,
      product_diff: 0,
      shipping_diff: 0,
      duties_diff: 0,
    }
  }
}

Resource.registerResource(OrderLine)