import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import users from './modules/users';
import orders from './modules/orders';
import templates from './modules/templates';
import i18n from '../i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: 'en',
    activitiesReversed: true,
    defaultCountry: 'BS',
    defaultLocale: 'en',
  },
  getters: {
    locale: state => state.locale
  },
  actions: {
    setLocale({ commit }, locale) {
      commit('setLocale', locale)
    },
    setDefaults({ commit }, {country,locale}) {
      commit('setDefaults', {country,locale})
    }
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale
      i18n.locale = locale
    },
    setDefaults(state, {country,locale}) {
      state.defaultCountry = country
      state.defaultLocale = locale
    },
    setActivitiesReversed: (state,reversed) => {
      state.activitiesReversed = reversed
    },
  },
  modules: {
    auth,
    users,
    orders,
    templates,
  },
  plugins: [createPersistedState({
    rehydrated: state => {
      i18n.locale = state.getters.locale
    }
  })]
});