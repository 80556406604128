import Debug from '@/components/Debug'
import { mapGetters } from 'vuex';
import { capitalize } from "@/utils";
export default {
  components: {Debug},
  data() {
    return {
      mode: null,
      loading: true,
      resource: this.$route.meta.resource,
      item: null,
      meta: null,
      parent: null,
      guardChanges: true,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      preferences: 'auth/preferences',
      features: 'auth/features',
      userRole: 'auth/role',
      userIsAdmin: 'auth/isAdmin',
      userIsSuperAdmin: 'auth/isSuperAdmin',
    }),
    validationObserver() {
      return this.$refs.template?.$refs.observer
    },
    creating() {
      return this.mode == "create"
    }
  },
  async created() {
    this.mode = this.$route.meta.mode
    await this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
    
      try {
        if (this.creating) {
          this.item = new this.resource(this.fill(),{
            title: this.$t("item.create-item", {
              item: this.$t(`${this.resource.plural}.singular`),
            }),
          })
        } else {
          this.item = await this.resource.get(this.$route.params.id)
        }
  
        if(this.resource.parent && this.$route.params.parent_id) {
          this.parent = await this.resource.parent.get(this.$route.params.parent_id)
        }
        this.fetched()
      } catch (error) {
        //
      } finally {
        this.loading = false
      }
    },
    fetched(){},
    saveFailed(){},
    fill() {
      return undefined
    },
    beforeSave(){
      return true
    },
    afterSave(){
        if(this.creating) {
          this.$router.replace({name: `${this.resource.plural}-show`,params:{id: this.item.id}})
        } else {
          this.$router.go(-1)
        }
    },
    async save() {
      this.loading = true
      try {
        if(await this.beforeSave() !== false) {
          let data = await this.item.$save()
  
          this.guardChanges = false
          
          this.afterSave(data)
        }
      } catch (error) {
        this.saveFailed(error)
      } finally {
        this.loading = false
      }
    },
    cancel() {
      this.$router.go(-1)
    }
  },
  metaInfo() {
    let resource = capitalize(this.$t(`${this.resource.plural}.singular`))
    let title = this.creating ? this.$t('common.new') : this.item?.$meta?.title

    return {
      title: `${resource}: ${title}`,
    }
  },
  async beforeRouteLeave(to,from,next) {
    if(this.guardChanges && this.validationObserver?.flags.dirty)  {
      let confirm = await this.$bvModal.msgBoxConfirm(this.$t(`item.confirm-unsaved-changes`),{
        autoFocusButton: 'ok',
        cancelTitle: this.$t('item.unsaved-changes-continue'),
        okTitle: this.$t('item.unsaved-changes-discard'),
        okVariant: 'danger',
      })
      next(confirm)
    } else next()
  }
}