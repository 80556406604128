<template>
  <div class="sidebar active" ref="sidebar">
    <div class="logo-content">
      <div class="logo">
        <img :src="logo" alt="AeroCollect">
        <!-- <i class="bx bxl-c-plus-plus"></i>
        <div class="logo-name">AeroCollect</div> -->
      </div>
      <i
        class="bx bx-menu"
        id="sidebar-toggle"
        ref="sidebar-toggle"
        @click="toggleSidebar"
      ></i>
    </div>
    <ul class="sidebar-list">
      <li v-for="entry in menu" :key="entry.route">
        <router-link :to="{name: entry.route}">
          <i :class="['bx',entry.icon]" ></i>
          <span class="link-name">{{ $t(entry.title) }}</span>
        </router-link>
        <span class="tooltip">{{ $t(entry.title) }}</span>
      </li>
    </ul>

    <div v-if="user" class="profile-content">
      <div class="profile">
        <div class="profile-details">
          <router-link :to="{name:'users-show',params: {id: user.id}}">
            <i class="bx bxs-user-rectangle bx-sm bx-tada-hover text-white"></i>
          </router-link>
          <div class="name-role">
            <div class="name" v-text="`${user.first_name} ${user.last_name}`" />
            <div class="role" v-text="$t(role)" />
          </div>
        </div>
        <i class="bx bx-log-out" id="signout" @click="doSignOut"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { roleMap, roleFilter, featureFilter } from "@/resources/User"
import access from '@/access'
import logo from '@/assets/logo.png'


const menu = [
  // {
  //   $roles: access['dashboard'],
  //   route: 'dashboard',
  //   icon: 'bxs-dashboard',
  //   title: 'dashboard.title'
  // },
  {
    $roles: access['orders'],
    route: 'orders',
    icon: 'bx-money',
    title: 'orders.title'
  },
  {
    $roles: access['templates'],
    route: 'templates',
    icon: 'bx-copy-alt',
    title: 'templates.title'
  },
  {
    $roles: access['users'],
    route: 'users',
    icon: 'bxs-group',
    title: 'users.title'
  },
]

export default {
  data() {
    return {
      menu: menu.filter(roleFilter).filter(featureFilter),
      logo
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    role() {
      return roleMap[this.user.role]
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut'
    }),
    toggleSidebar() {
      let sidebar = this.$refs["sidebar"];
      let toggler = this.$refs["sidebar-toggle"];

      sidebar.classList.toggle("active");
      if (toggler.classList.contains("bx-menu")) {
        toggler.classList.replace("bx-menu", "bx-menu-alt-right");
      } else {
        toggler.classList.replace("bx-menu-alt-right", "bx-menu");
      }
    },
    async doSignOut() {
      await this.signOut()
      this.$router.push({name: 'signin'});
    }
  },
};
</script>