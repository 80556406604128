<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bx-copy-alt"></i>
            {{ $t('templates.label') }}
          </template>

          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input
                label="common.name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.name"
              />
            </div>
            <div class="col">
              <form-input 
                type="select"
                label="orders.locale" 
                icon="bx-message-square-detail"
                v-model="item.locale"
                rules="required"
                :options="resource.locales | transMap"
                :clearable="false"
              />
            </div>
          </div>
        </b-card>
        <b-card>
          <template #header>
            <i class="bx bx-chat"></i>
            {{ $t('templates.messages') }}
          </template>

          <form-input
            label="templates.subject" 
            icon="bx-label"
            rules="required" 
            v-model="item.subject"
            @input="syncVariables"
          />
          <form-input 
            type="textarea"
            icon="bx-envelope"
            rules="required"
            label="templates.email"
            v-model="item.email"
            @input="syncVariables"
            :description="$t('templates.email-description')"
          />
          <form-input 
            type="textarea"
            icon="bx-message-detail"
            rules="required"
            label="templates.sms"
            v-model="item.sms"
            @input="syncVariables"
            :description="$t('templates.sms-description')"
          />

        </b-card>
      </div>
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bx-code-curly"></i>
            {{ $t('templates.variables.label') }}
          </template>

          <small>
            <div v-html="$t('templates.variables.description')" class="mb-4" />
          </small>

          <VariablesManager :conf="item.configuration" :template="variablesInTemplates" />
        </b-card>
      </div>
    </div>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import ItemEditTemplate from "@/components/ItemEditTemplate";
import VariablesManager from "@/components/VariablesManager";
import FormInput from "@/components/Form/Input";
import { arrayDiff } from '@/utils';

const permanentVariables = [
  'order_number',
  'order_lines',
  'amount',
  'product_diff',
  'shipping_diff',
  'duties_diff',
]

export default {
  name: 'EditTemplate',
  extends: ItemEdit,
  components: { 
    ItemEditTemplate, 
    FormInput,
    VariablesManager,
  },
  data() {
    return {
      variablesInTemplates: []
    }
  },
  computed: {
  },
  methods: {
    syncVariables() {
      const {subject,email,sms} = this.item
      const regex = /\{([\d\w_]+)\}/g;

      this.variablesInTemplates = arrayDiff([...new Set([...(subject+email+sms).matchAll(regex)].map(m => m[1]))],permanentVariables)
    }
  }
};
</script>