<template>
  <GuestLayout>
    <form v-if="!forgot" class="signin-form" @submit.prevent="submit">
      <img src="@/assets/logo.png" alt="AeroCollect" width="400" class="mb-4">
      <b-card
        style="max-width: 24rem"
        class="mx-auto"
      >

        <b-alert v-if="message" show variant="info">{{message}}</b-alert>
        <b-alert v-if="error" show variant="danger">{{error}}</b-alert>

        <b-card-text>
          <b-form-input :autofocus="!email" v-model="email" :placeholder="$t('signin.email')" class="mb-4"></b-form-input>
          <b-form-input :autofocus="email && !password" v-model="password" :placeholder="$t('signin.password')" type="password"></b-form-input>
        </b-card-text>

        <b-button class="float-right mb-3 text-muted" variant="link" @click="(forgot = true)">{{$t('signin.forgot-password')}}</b-button>
        <b-button block variant="dark" type="submit">{{$t('signin.signin')}}</b-button>
      </b-card>
    </form>
    <form v-else class="forgot-password-form" @submit.prevent="submitForgot">
      <b-card
        :header="$t('signin.forgot-header')"
        style="max-width: 24rem"
        class="mx-auto"
      >
        <b-alert v-if="error" show variant="danger">{{error}}</b-alert>

        <template v-if="message">
          <b-alert show variant="info">{{message}}</b-alert>
        </template>
        <template v-else>
          <p class="small">{{$t('signin.forgot-info')}}</p>

          <b-card-text>
            <b-form-input autofocus v-model="email" :placeholder="$t('signin.email')"></b-form-input>
          </b-card-text>

          <b-button variant="light" @click="forgot = false">{{$t('common.cancel')}}</b-button>
          <b-button variant="primary" type="submit" class="float-right">{{$t('signin.forgot-button')}}</b-button>
        </template>
      </b-card>
    </form>
  </GuestLayout>
</template>

<script>
import GuestLayout from "@/layouts/Guest";
import { mapActions } from "vuex";

export default {
  components: { GuestLayout },
  data() {
    let message
    if(this.$route.params.message) {
      message = this.$t(`signin.message-${this.$route.params.message}`)
    }

    return {
      forgot: false,
      message: message,
      error: false,
      email: this.$route.params.email,
      password: null
    }
  },
  watch: {
    forgot() {
      this.error = false
      this.message = false
    }
  },
  methods: {
    ...mapActions({
      user: 'auth/user',
      signIn: 'auth/signIn',
      signOut: 'auth/signOut',
      forgotPassword: 'auth/forgotPassword',
    }),
    async submit() {
      this.error = false
      const data = new FormData();
      data.append("email", this.email);
      data.append("password", this.password);
      try {
          let response = await this.signIn(data);

          if(response.data?.error == 'Already authenticated.') {
            await this.signOut();
            response = await this.signIn(data);
          }

          this.$router.push({name: 'orders'});
      } catch (error) {
        this.handleError(error)
      }
    },
    async submitForgot() {
      this.error = false
      const data = new FormData();
      data.append("email", this.email);
      try {
          await this.forgotPassword(data);
          this.message = this.$t('signin.forgot-complete')
      } catch (error) {
        this.handleError(error)
      }
    },
    handleError(error) {
      if(error.request.status === 422) {
        this.error = this.$t('signin.incorrect-credentials')
      } else {
        this.error = this.$t('signin.other-error')
      }
    }
  },
  metaInfo() {
    return {
      title: this.$t('signin.title'),
    }
  },
};
</script>

<style scoped>
  /* .signin-form input[type=text]{
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .signin-form input[type=password]{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  } */
</style>