<template>
  <b-modal 
    ref="modal" 
  >
    <template #modal-title>
      {{$t('orders.modals.confirm-order-number.title')}}
    </template>

    <p>{{$t('orders.modals.confirm-order-number.description')}}</p>

    <form-input 
      autofocus
      label="orders.order_number" 
      icon="bxs-purchase-tag"
      v-model="order_number"
    />

    <template #modal-footer>
      <b-button @click="go" variant="primary">
        {{$t('common.confirm')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import FormInput from "@/components/Form/Input";

export default {
  name: 'ConfirmOrderNumber',
  extends: Modal,
  components: {FormInput},
  props: {
  },
  mounted() {
  },
  data() {
    return {
      order_number: null
    }
  },
  methods: {
    async go() {
      this.close(this.order_number)
    }
  }
}
</script>