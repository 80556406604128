import i18n, { availableLocales } from "@/i18n";
import Resource from "@/services/Resource";
import {deleteBatchAction, nameField } from "./common";

export default class Template extends Resource {
  static name = 'template'
  static icon = 'bx-copy-alt'

  static fields = [
    nameField(),
    { 
      key: 'locale', 
      label: 'common.locale',
      formatter: v => i18n.t(`locale.${v}`)
    },
  ]
  
  static filters = [
    {
      type: 'select',
      name: 'locale',
      label: 'common.locale',
      options: availableLocales,
    },
  ]

  static actions = [
    // {
    //   name: 'approve',
    //   icon: 'bx-list-check',
    //   variant: 'info',
    //   async action(item) {
    //     await item.$approve()

    //     return {refresh: true}
    //   }
    // },
  ]

  static batchActions = [
    deleteBatchAction()
  ]

  get defaults() {
    return {
      name: null,
      subject: null,
      email: null,
      sms: null,
      configuration: {
        variables: []
      }
    }
  }

  static get locales() {
    return availableLocales
  }

  static variableTypes = [
    {code: 'string', label: 'templates.variables.types.string'},
    {code: 'integer', label: 'templates.variables.types.integer'},
    {code: 'float', label: 'templates.variables.types.float'},
  ]
}

Resource.registerResource(Template)