<template>
  <div class="guest-layout">
    <div class="header">
      <ul class="nav nav-pills">
        <li v-for="locale in availableLocales" class="nav-item" :key="locale.code">
          <a class="nav-link ml-2" :class="{active: currentLocale == locale.code}" href="#" @click.stop="setLocale(locale.code)">{{locale.code}}</a>
        </li>
        <slot name="header"></slot>
      </ul>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import i18n, { availableLocales } from '../i18n'

export default {
  data() {
    return {
      availableLocales
    }
  },
  computed: {
    currentLocale() {
      return i18n.locale
    }
  },
  methods: {
    ...mapActions({
      setLocale: 'setLocale',
    }),
  }
}
</script>

<style lang="scss" scoped>
  @import "@/css/variables.scss";

  .guest-layout {
    background-color: $primary;
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 50vmin;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .header {
      position: absolute;
      top: 1rem;
      right: 1rem;

      .nav-link {
        &:not(.active) {
          color: white;
        }

        &.active {
          background-color: white;
          color: black;
        }
      }
    }
  }
</style>