<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bxs-user"></i>
            {{ $t('orders.customer') }}
          </template>

          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input
                label="common.first_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.first_name"
              />
            </div>
            <div class="col">
              <form-input
                label="common.last_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.last_name"
              />
            </div>
          </div>

          <PhoneInput required label="common.phone" v-model="item" />

          <form-input
            type="email"
            label="common.email" 
            icon="bx-envelope"
            rules="email|required"
            v-model="item.email"
          />

        </b-card>

        <b-card>
          <template #header>
            <i class="bx bxs-package"></i>
            {{ $t("orders.order") }}
          </template>

          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input 
                label="orders.order_number" 
                icon="bxs-purchase-tag"
                rules="required"
                v-model="item.order_number"
                :obfuscate="loading"
              />
            </div>
            <div class="col">
              <form-input 
                type="select"
                label="orders.locale" 
                icon="bx-message-square-detail"
                v-model="item.locale"
                rules="required"
                :options="resource.locales | transMap"
                :clearable="false"
              />
            </div>
            <div class="col">
              <form-input 
                type="select"
                label="common.currency" 
                icon="bx-money"
                v-model="item.currency"
                rules="required"
                :options="resource.currencies | transMap"
                :clearable="false"
              />
            </div>
          </div>
        </b-card>

      </div>
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bx-copy-alt"></i>
            {{ $t("templates.label") }}
          </template>

          <form-input
            type="item" 
            label="orders.template" 
            icon="bxs-copy-alt" 
            v-model="item.template_id"
            resource-name="templates"
            rules="required"
            :refreshOn="item.locale"
            :scope="[['locale', item.locale]]"
            :clearable="false"
            @input="fetchTemplate"
          />
        </b-card>

        <b-card v-if="variables.length">
          <template #header>
            <i class="bx bx-code-curly"></i>
            {{ $t('templates.variables.label') }}
          </template>
          <div v-for="variable in variables" :key="variable.name">
              <form-input
                :label="variable.name.replace('_',' ')" 
                icon="bx-code-curly"
                :rules="variableRules(variable.type)" 
                v-model="item.variables[variable.name]"
                :description="variable.description"
              />

          </div>
        </b-card>
      </div>
    </div>

        <b-card no-body>
          <template #header>
            <i class="bx bx-package"></i>
            {{ $t("orders.order-lines") }}
          </template>

          <table class="table m-0 table-responsive">
            <thead>
              <tr>
                <th nowrap>{{$t('order_lines.line_number')}}</th>
                <th nowrap>{{$t('order_lines.description')}}</th>
                <th nowrap>{{$t('order_lines.link')}}</th>
                <th nowrap>{{$t('order_lines.quantity')}}</th>
                <th nowrap v-for="{field,label} in resource.diffs" :key="field">
                  {{$t(label)}}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(orderLine,i) in item.order_lines" :key="i">
                <td width="80px">
                  <form-input v-model="orderLine.line_number" />
                </td>
                <td>
                  <form-input v-model="orderLine.name" />
                </td>
                <td>
                  <form-input v-model="orderLine.link" />
                </td>
                <td>
                  <form-input type="number" min="1" step="1" style="width: 80px" v-model="orderLine.quantity" />
                </td>
                <td v-for="{field} in resource.diffs" :key="field" width="100px">
                  <form-input>
                    <currency-input 
                      class="form-control"
                      v-model="orderLine[field]" 
                      :currency="item.currency"
                      :locale="$i18n.locale"
                      value-as-integer
                    />
                  </form-input>
                </td>
                <td>
                  <b-button size="sm" class="float-right" variant="outline-danger" @click="removeOrderLine(i)">
                    <i class='bx bx-trash' ></i>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>

          <b-card-footer>
            <b-button size="sm" class="float-right" variant="primary" @click="addOrderLine">
              <i class='bx bx-plus' ></i>
              {{$t('orders.add-order-line')}}
            </b-button>
          </b-card-footer>
        </b-card>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import ItemEditTemplate from "@/components/ItemEditTemplate";
import FormInput from "@/components/Form/Input";
import PhoneInput from '@/components/Form/PhoneInput';
import { mapActions } from 'vuex';
import Template from '@/resources/Template';
import ConfirmOrderNumber from "@/components/Modals/ConfirmOrderNumber";
import OrderLine from '@/resources/OrderLine';

export default {
  name: 'EditOrder',
  extends: ItemEdit,
  components: { 
    ItemEditTemplate, 
    FormInput,
    PhoneInput,
  },
  data() {
    return {
      template: null,
    }
  },
  computed: {
    totalDiff() {
      return this.item.product_diff + this.item.shipping_diff + this.item.duties_diff
    },
    variables() {
      return this.template?.configuration?.variables || []
    }
  },
  methods: {
    ...mapActions(['setDefaults']),
    fetched() {
      this.$set(this.item,'order_lines',this.item.$orderLines)

      if(this.creating) {
        this.addOrderLine()
      }

      if(!this.creating && this.item.template_id) {
        this.fetchTemplate(this.item.template_id)
      }
    },
    addOrderLine() {
      this.item.order_lines.push(new OrderLine)
    },
    removeOrderLine(i) {
      this.item.order_lines.splice(i,1)
    },
    async beforeSave(){
      if(this.creating) {
        const orderNumber = await this.$modal(ConfirmOrderNumber)

        if(orderNumber === false) return false
        else if(orderNumber !== this.item.order_number) {
          this.$bvModal.msgBoxOk(this.$t('orders.errors.order-number-mismatch'),{
            autoFocusButton: 'ok',
            okVariant: 'danger'
          })
          return false
        }

        this.setDefaults({
          country: this.item.country,
          locale: this.item.locale,
        })
      }
    },
    async fetchTemplate(templateId) {
      if(!templateId) {
        this.template = null
        return
      }

      this.loading = true

      try {
        this.template = await Template.get(templateId)
      } finally {
        this.loading = false
      }
    },
    variableRules(type) {
      switch(type) {
        case 'string': return 'required'
        case 'integer': return 'required|integer'
        case 'float': return 'required|double:0,dot'
      }
    }
  }
};
</script>