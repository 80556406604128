import { extend, configure } from 'vee-validate';
import { 
  required, 
  required_if, 
  min, 
  max, 
  email, 
  excluded, 
  alpha_dash, 
  integer,
  double
} from 'vee-validate/dist/rules';
import i18n from './i18n';
import { isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js/mobile'
import callingCodes from '@/assets/dialling_codes.json'

configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  }
});

extend('required', required);
extend('required_if', required_if);
extend('min', min);
extend('max', max);
extend('email', email);
extend('excluded', excluded);
extend('alpha_dash', alpha_dash);
extend('integer', integer);
extend('double', double);
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  }
});
extend('gt0', { validate: num => num > 0 })

extend('required_without', {
  params: ['target'],
  computesRequired: true,
  validate(value, {target}) {
    return {
      valid: value || target,
      required: !target,
    };
  },
});

extend('phone', { 
  params: ['country'],
  validate(num,{country}) {
    const callingCode = callingCodes[country]
    const numToValidate = `+${callingCode}${num}`
    
    if(!isValidPhoneNumber(numToValidate,country)) {
      const reason = validatePhoneNumberLength(numToValidate,country)
      return i18n.t('validation.phone.' + (reason || 'INVALID'))
    }

    return true
  }
})