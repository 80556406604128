<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
          <small><status v-bind="{item}" /></small>
        </h1>

        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4">
          <div class="col mb-4">
            <b-card>
              <template #header>
                <i class="bx bxs-user"></i>
                {{ $t('orders.customer') }}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="common.name" icon="bx-purchase-tag" :value="item.name"/>
                  </div>
                  <div class="col">
                    <value label="common.phone" icon="bx-phone" :value="'+'+item.full_phone_number"/>
                  </div>
                  <div class="col-2">
                    <value label="common.email" icon="bx-envelope" :value="item.email"/>
                  </div>
                </div>
              </dl>
            </b-card>
            <b-card>
              <template #header>
                <i class="bx bxs-package"></i>
                {{ $t("orders.order") }}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="orders.order_number" icon="bxs-purchase-tag" :value="item.order_number"/>
                  </div>
                  <div class="col">
                    <value label="common.locale" icon="bx-message-square-detail" :value="getLocaleName(item.locale)" />
                  </div>
                  <div class="col">
                    <value label="common.currency" icon="bx-money" :value="item.currency" />
                  </div>
                </div>
              </dl>

              <!-- <hr>

              <div class="container mt-4 px-0">
                <div class="row py-1" v-for="{field,icon,label} in resource.diffs" :key="field">
                  <div class="col">
                    <i :class="['bx',icon]" ></i>
                    {{$t(label)}}
                  </div>
                  <div class="col text-right">
                    {{formatCurrency(item[field])}}
                  </div>
                </div>
                <hr>
                <div class="row py-1">
                  <div class="col">
                    <i class='bx bx-dollar-circle' ></i>
                    {{$t('orders.amount')}}
                  </div>
                  <div class="col text-right">
                    <strong>{{formatCurrency(item.amount)}}</strong>
                  </div>
                </div>
              </div> -->

            </b-card>

            <activity-card :item="item" />
          </div>
          <div class="col mb-4">
            <b-card v-if="template">
              <template #header>
                <i class="bx bx-copy-alt"></i>
                {{ $t('orders.template-preview') }}
              </template>

              <dl>
                <value label="orders.template" icon="bx-copy-alt" :value="item.template_name">
                  <router-link v-if="item.template_id" :to="{name: `templates-show`, params: {id: item.template_id}}">
                    {{item.template_name}}
                  </router-link>
                </value>
                <value label="templates.email-preview" icon="bx-envelope" :value="true">
                  <p class="substitutions d-block text-initial bx-border px-3 mb-2" style="white-space: pre-wrap" v-html="substitute(template.subject)" />
                  <p class="substitutions d-block text-initial bx-border p-3 mb-0" style="white-space: pre-wrap" v-html="substitute(greeting + template.email)" />
                  <small class="text-muted">{{$t('templates.email-description')}}</small>
                </value>
                <value label="templates.sms-preview" icon="bx-message-detail" :value="true">
                  <p class="substitutions d-block text-initial bx-border p-3 mb-0" style="white-space: pre-wrap" v-html="substitute(greeting + template.sms)" />
                  <small class="text-muted">{{$t('templates.sms-description')}}</small>
                </value>
              </dl>
            </b-card>

          </div>
        </div>

        <b-card no-body>
          <template #header>
            <i class="bx bx-package"></i>
            {{ $t("orders.order-lines") }}
          </template>

          <table class="table m-0 table-responsive">
            <thead>
              <tr>
                <th nowrap>{{$t('order_lines.line_number')}}</th>
                <th nowrap>{{$t('order_lines.description')}}</th>
                <th nowrap>{{$t('order_lines.link')}}</th>
                <th nowrap class="text-right">{{$t('order_lines.quantity')}}</th>
                <th nowrap class="text-right" v-for="{field,label} in resource.diffs" :key="field">
                  {{$t(label)}}
                </th>
                <th nowrap class="text-right">{{$t('order_lines.amount')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(orderLine,i) in item.$meta.order_lines" 
                :key="i"
                :class="rowClass(orderLine)"
              >
                <td width="80px">
                  <span>{{orderLine.line_number}}</span>
                </td>
                <td width="80%">
                  <span class="ellipsis">{{orderLine.name}}</span>
                </td>
                <td width="20%">
                  <a class="ellipsis no-icon" target="_blank" v-if="orderLine.link" :href="orderLine.link">{{orderLine.link}}</a>
                </td>
                <td class="text-right" width="80px">
                  {{orderLine.quantity}}
                </td>
                <td class="text-right" v-for="{field} in resource.diffs" :key="field" width="100px">
                  {{formatCurrency(orderLine[field])}}
                </td>
                <td class="text-right font-weight-bold">
                  {{formatCurrency(orderLine.amount)}}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class>
                <td class="font-weight-bold" colspan=7>{{$t('order_lines.amount')}}</td>
                <!-- <td class="text-right">
                  {{item.quantity}}
                </td>
                <td class="text-right" v-for="{field} in resource.diffs" :key="field" width="15%">
                  {{formatCurrency(item[field])}}
                </td> -->
                <td class="text-right font-weight-bold">
                  {{formatCurrency(item.amount)}}
                </td>
              </tr>
            </tfoot>
          </table>
        </b-card>
      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/Main";
import ItemShow from "@/components/ItemShow";
import Value from "@/components/Value";
import Status from "@/components/Status";
import ActivityCard from "@/components/Cards/Activity";
import ActionBar from '@/components/ActionBar.vue'
import { availableLocales } from '@/i18n';
import Template from '@/resources/Template';
import { STATUS_AWAITING_RESPONSE, STATUS_CUSTOMER_CANCELLED } from '@/resources/Order';

export default {
  name: 'ShowOrder',
  extends: ItemShow,
  data() {
    return {
      template: null,
      greeting: this.$t('template.greeting') + "\n\n"
    }
  },
  components: {
    MainLayout,
    ActivityCard,
    Value,
    ActionBar,
    Status,
  },
  methods: {
    fetched() {
      if(!this.creating && this.item.template_id) {
        this.fetchTemplate(this.item.template_id)
      }
    },
    getLocaleName(locale) {
      return this.$t(availableLocales.find(l => l.code == locale).label)
    },
    formatCurrency(value) {
      return new Intl.NumberFormat(this.item.locale, { 
        style: 'currency', 
        currency: this.item.currency, 
      }).format(value/100)
    },
    async fetchTemplate(templateId) {
      this.loading = true

      try {
        this.template = await Template.get(templateId)
      } finally {
        this.loading = false
      }
    },
    substitute(string) {
      const values = {
        full_name: this.item.full_name,
        order_number: this.item.order_number,
        order_lines: this.item.$meta.order_lines.map(l => `${l.quantity} &times; ${l.name}`).join('\n'),
        product_diff: this.formatCurrency(this.item.product_diff),
        shipping_diff: this.formatCurrency(this.item.shipping_diff),
        duties_diff: this.formatCurrency(this.item.duties_diff),
        amount: this.formatCurrency(this.item.amount),
        ...this.item.variables,
      }

      return string.replaceAll(/\{([\d\w_]+)\}/g, (m,k) => '<span>'+(values[k] || `[MISSING VALUE FOR '${k}']`)+'</span>')
    },
    rowClass(orderLine) {
      const classes = []
      if(this.item.status === STATUS_CUSTOMER_CANCELLED) {
        classes.push('table-danger')
      } else if(this.item.status > STATUS_AWAITING_RESPONSE) {
        classes.push(orderLine.selected ? 'table-success' : 'table-danger')
      }

      return classes
    }
  }
};
</script>

<style lang="scss">
  .substitutions {
    span {
      opacity: .6;
      font-style: italic;
    }
  }
</style>