/*eslint no-empty-pattern: "off"*/
import api from '@/api';
import { ROLE_CUSTOMER_SUPPORT, ROLE_SUPERADMIN } from '@/resources/User';
const state = {
  user: null,
  preferences: {},
};

const getters = {
  user: state => state.user,
  preferences: state => state.preferences,
  isAuthenticated: state => !!state.user,
  role: state => state.user?.role || 0,
  features: state => (state.user?.features || 0),
  isAdmin: state => state.user?.role & (ROLE_SUPERADMIN | ROLE_CUSTOMER_SUPPORT),
  isSuperAdmin: state => state.user?.role & ROLE_SUPERADMIN,
};

const actions = {
  async signIn({ dispatch }, data) 
  {
    await api.get('../sanctum/csrf-cookie')
    let response = await api.post('../login', data,{
      suppress: [422]
    })
    dispatch('setUser', response.data.user)
    return response
  },
  async signOut({ dispatch }) 
  {
    let response = await api.post('../logout')
    dispatch('setUser', null)
    return response
  },
  async forgotPassword({},data) 
  {
    await api.get('../sanctum/csrf-cookie')
    await api.post('../forgot-password', data)
  },
  async resetPassword({},data) 
  {
    await api.get('../sanctum/csrf-cookie')
    await api.post('../reset-password', data)
  },
  setUser({ commit },user) 
  {
    commit('setUser',user)
    if(user?.locale) {
      commit('setLocale',user.locale,{ root: true })
    }
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user || null
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};