import { 
  ROLE_ALL, 
  ROLE_ADMIN,
} from "./resources/User"

export default {
  'dashboard': ROLE_ALL,

  'orders': ROLE_ADMIN,
  'orders-show': ROLE_ADMIN,
  'orders-create': ROLE_ADMIN,
  'orders-edit': ROLE_ADMIN,

  'templates': ROLE_ADMIN,
  'templates-show': ROLE_ADMIN,
  'templates-create': ROLE_ADMIN,
  'templates-edit': ROLE_ADMIN,

  'users': ROLE_ADMIN,
  'users-show': ROLE_ADMIN,
  'users-create': ROLE_ADMIN,
  'users-edit': ROLE_ADMIN,
  'users-profile': ROLE_ALL,
}