<template>
  <div>
    <div 
      v-for="(variable,i) in conf.variables" 
      :key="i" 
      class="row row-cols-1 row-cols-xl-2 bx-border mx-0 mb-4 pt-4"
    >
      <div class="col">
        <form-input
          label="templates.variables.name" 
          icon="bx-code-curly"
          rules="required|excluded:order_number,order_lines,amount,product_diff,shipping_diff,duties_diff" 
          v-model="variable.name"
          :formatter="variableFormatter"
        />
      </div>
      <div class="col">
        <form-input
          type="select"
          :options="types | transMap"
          label="common.type" 
          icon="bx-rename"
          rules="required" 
          v-model="variable.type"
          :clearable="false"
        />
      </div>
      <div class="col">
        <form-input
          label="templates.variables.example" 
          icon="bx-fullscreen"
          :rules="sampleRules(variable.type)" 
          v-model="variable.example"
        />
      </div>
      <div class="col">
        <form-input
          label="common.description" 
          icon="bx-help-circle"
          v-model="variable.description"
        />
      </div>
    </div>
    <b-button @click="add" variant="" size="sm" class="w-100">
      <i class='bx bx-plus' ></i>
      {{ $t("templates.variables.add") }}
    </b-button>
  </div>
</template>

<script>
import FormInput from "@/components/Form/Input";
import { arrayDiff } from '@/utils';
import Template from '@/resources/Template'

const emptyVariable = {
  name: null,
  type: 'string',
  example: null,
  description: null,
}


export default {
  name: 'VariablesManager',
  components: {
    FormInput,
  },
  props: {
    conf: {
      type: Object,
      required: true,
    },
    template: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      types: Template.variableTypes
    }
  },
  watch: {
    template() {
      this.autoPopulate()
    }
  },
  computed: {
    populated() {
      return this.conf.variables
        .filter(v => v.example)
        .map(v => v.name)
    }
  },
  methods: {
    add() {
      this.conf.variables.push({...emptyVariable})
    },
    autoPopulate() {
      this.conf.variables = this.conf.variables.filter(v => v.example)

      arrayDiff(this.template,this.populated)
        .forEach(v => this.conf.variables.push({...emptyVariable, name: v}))

    },
    variableFormatter(value) {
      return value.replace(/[^\d\w_]/g,'')
    },
    sampleRules(type) {
      switch(type) {
        case 'string': return 'required'
        case 'integer': return 'required|integer'
        case 'float': return 'required|double:0,dot'
      }
    }
  }
}
</script>