<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
        </h1>

        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4">
          <div class="col mb-4">
            <b-card>
              <template #header>
                <i class="bx bx-copy-alt"></i>
                {{ $t('templates.label') }}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="common.name" icon="bx-purchase-tag" :value="item.name"/>
                  </div>
                  <div class="col">
                    <value label="users.preferences.locale" icon="bx-message-square-detail" :value="getLocaleName(item.locale)" />
                  </div>
                </div>
              </dl>
            </b-card>
            <b-card>
              <template #header>
                <i class="bx bx-chat"></i>
                {{ $t('templates.messages') }}
              </template>

              <dl>
                <value label="templates.email" icon="bx-envelope" :value="true">
                  <p class="substitutions d-block text-initial bx-border px-3 mb-2" style="white-space: pre-wrap" v-html="substitute(item.subject)" />
                  <p class="substitutions mb-0 d-block text-initial bx-border p-3 mb-0" style="white-space: pre-wrap" v-html="substitute(greeting + item.email)" />
                  <small class="text-muted">{{$t('templates.email-description')}}</small>
                </value>
                <value label="templates.sms" icon="bx-message-detail" :value="true">
                  <p class="substitutions d-block text-initial bx-border p-3 mb-0" style="white-space: pre-wrap" v-html="substitute(greeting + item.sms)" />
                  <small class="text-muted">{{$t('templates.sms-description')}}</small>
                </value>
              </dl>
            </b-card>

          </div>
          <div class="col mb-4">
            <b-card v-if="item.configuration.variables.length">
              <template #header>
                <i class="bx bx-code-curly"></i>
                {{ $t('templates.variables.label') }}
              </template>
              <p v-for="variable in item.configuration.variables" :key="variable.name">
                <strong>{{variable.name}}</strong> <b-badge>{{$t(typeName(variable.type))}}</b-badge>
                {{$t('common.eg')}} "<code>{{variable.example}}</code>"
                <br>
                <span>{{variable.description}}</span>
              </p>
            </b-card>
            <activity-card :item="item" />
          </div>
        </div>
      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/Main";
import ItemShow from "@/components/ItemShow";
import Value from "@/components/Value";
import ActivityCard from "@/components/Cards/Activity";
import ActionBar from '@/components/ActionBar.vue'
import { availableLocales } from '@/i18n';

export default {
  name: 'ShowOrder',
  extends: ItemShow,
  data() {
    return {
      greeting: this.$t('template.greeting') + "\n\n"
    }
  },
  components: {
    MainLayout,
    ActivityCard,
    Value,
    ActionBar,
  },
  computed: {
    variables() {
      return {
        full_name: 'John Doe',
        order_number: 'ABC12345',
        order_lines: '1 &times; NIKE AIR MAX\n3 &times; NIKE LACES',
        product_diff: this.formatCurrency(425),
        shipping_diff: this.formatCurrency(250),
        duties_diff: this.formatCurrency(100),
        amount: this.formatCurrency(775),
        ...this.item.configuration.variables.reduce((c,v) => {
          c[v.name] = v.example
          return c
        },{})
      }
    }
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat(this.item.locale, { 
        style: 'currency', 
        currency: 'USD', 
      }).format(value/100)
    },
    substitute(string) {
      return string.replaceAll(/\{([\d\w_]+)\}/g, (m,k) => '<span>'+(this.variables[k] || k)+'</span>')
    },
    getLocaleName(locale) {
      return this.$t(availableLocales.find(l => l.code == locale).label)
    },
    typeName(type){
      return this.resource.variableTypes.find(t => t.code === type).label
    }
    
  }
};
</script>

<style lang="scss">
  .substitutions {
    span {
      opacity: .6;
      font-style: italic;
    }
  }
</style>