<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <template #tags>
      <span v-if="isOwn" class="small text-muted">Me</span>
    </template>

    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bxs-user"></i>
            {{ $t("users.label") }}
          </template>
          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input
                label="common.first_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.first_name"
              />
            </div>
            <div class="col">
              <form-input
                label="common.last_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.last_name"
              />
            </div>
          </div>
          <form-input 
            label="common.email" 
            icon="bx-envelope"
            v-model="item.email"
            rules="required|email"
            :description="creating ? $t('users.email-info') : null"
          />
        </b-card>

        <b-card v-if="userIsAdmin">
          <template #header>
            <i class="bx bxs-user-detail"></i>
            {{ $t("users.role") }}
          </template>
          <form-input 
            type="select"
            label="users.role" 
            icon="bxs-user-detail"
            v-model="item.role"
            rules="required"
            :clearable="false"
            :options="roleOptions"
          />
        </b-card>

      </div>
      <div class="col mb-4">
        <b-card v-if="isOwn">
          <template #header>
            <i class="bx bx-cog"></i>
            {{ $t("users.preferences.label") }}
          </template>
          <form-input 
            type="select"
            label="users.preferences.locale" 
            icon="bx-message-square-detail"
            v-model="item.locale"
            rules="required"
            :options="resource.locales | transMap"
            :clearable="false"
          />

        </b-card>

        <b-card v-if="isOwn">
          <template #header>
            <i class="bx bx-key"></i>
            {{ $t("users.password") }}
          </template>

          <template v-if="passwordResetSent">
            <b-alert show variant="info">{{$t('users.change-password-sent')}}</b-alert>
          </template>
          <template v-else>
            <p>
              {{ $t('users.change-password-info')}}
            </p>
            <div class="text-center">
              <b-button @click="resetPassword" variant="primary">
                {{ $t("users.change-password-button") }}
              </b-button>
            </div>
          </template>
        </b-card>

      </div>
    </div>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import ItemEditTemplate from "@/components/ItemEditTemplate";
import FormInput from "@/components/Form/Input";
import { roleMap, ROLE_ADMIN, ROLE_CUSTOMER_SUPPORT, ROLE_SUPERADMIN } from '@/resources/User';
import { mapActions, mapGetters } from 'vuex';

export default {
  extends: ItemEdit,
  components: { ItemEditTemplate, FormInput },
  data() {
    return {
      isOwn: false,
      passwordResetSent: false,
      env: process.env,
    }
  },
  created() {
    if(this.user.id == this.$route.params.id) {
      this.isOwn = true
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      userRole: 'auth/role',
      userIsAdmin: 'auth/isAdmin',
    }),
    itemIsAdmin() {
      return this.item.role & ROLE_ADMIN
    },
    roleOptions() {
      let superadmin = {label: this.$t(roleMap[ROLE_SUPERADMIN]), code: ROLE_SUPERADMIN}
      let supportUser = {label: this.$t(roleMap[ROLE_CUSTOMER_SUPPORT]), code: ROLE_CUSTOMER_SUPPORT}

      switch(this.userRole) {
        case(ROLE_SUPERADMIN):
          return [superadmin,supportUser]
        case(ROLE_CUSTOMER_SUPPORT):
          return [supportUser]
        default:
          return []
      }
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      setUser: 'auth/setUser',
      forgotPassword: 'auth/forgotPassword',
    }),
    async resetPassword() {
      const data = new FormData();
      data.append("email", this.user.email);
      try {
        await this.signOut()
        await this.forgotPassword(data);
        this.passwordResetSent = true
      } catch (error) {
        console.log({error})
      }
    },
    afterSave(data) {
      if(this.isOwn) {
        this.setUser(data)
      }

      if(this.isOwn || this.creating) {
        this.$router.replace({name: `${this.resource.plural}-show`,params:{id: this.item.id}})
      } else {
        this.$router.go(-1)
      }
    }
  }
};
</script>