import store from '@/store'
import Resource from "@/services/Resource";
import i18n, { availableLocales } from '@/i18n';
import { activeField, activeFilter, deleteBatchAction, nameField } from './common';

export const ROLE_SUPERADMIN       = 1
export const ROLE_CUSTOMER_SUPPORT = 2

export const ROLE_ALL   = ROLE_SUPERADMIN | ROLE_CUSTOMER_SUPPORT
export const ROLE_ADMIN = ROLE_SUPERADMIN | ROLE_CUSTOMER_SUPPORT

export const roleMap = {
  [ROLE_SUPERADMIN]: 'role.superadmin',
  [ROLE_CUSTOMER_SUPPORT]: 'role.customer_support',
}

export default class User extends Resource {
  static name = 'user'
  static icon = 'bx-group'
  static fields = [
    nameField(),
    { 
      key: 'email', 
      sortable: true,
      label: 'common.email'
    },
    { 
      key: 'role', 
      sortable: true,
      label: 'users.role',
      formatter: (value) => i18n.t(roleMap[value])
    },
    activeField(),
  ]
  
  static filters = [
    activeFilter(),
    {
      $roles: ROLE_ADMIN,
      name: 'role',
      label: 'users.role',
      type: 'select',
      options: this.roles,
    },
  ]


  static actions = [
    {
      name: 'request-token',
      icon: 'bxs-key',
      async action(item) {
        await item.$requestToken()
        return {refresh: false}
      }
    }
  ]

  static batchActions = [
    deleteBatchAction()
  ]

  get roleName() {
    return roleMap[this.role]
  }

  static get locales() {
    return availableLocales
  }

  // get defaults() {
  //   let user = store.getters['auth/user']

  //   return {
  //   }
  // }

  get name() {
    return `${this.first_name} ${this.last_name}`
  }

  static get roles() {
    return [
      {label: i18n.t(roleMap[ROLE_SUPERADMIN]), code: ROLE_SUPERADMIN},
      {label: i18n.t(roleMap[ROLE_CUSTOMER_SUPPORT]), code: ROLE_CUSTOMER_SUPPORT},
    ]
  }
}

export const roleFilter = obj => !obj.$roles || store.getters['auth/role'] & obj.$roles
export const featureFilter = obj => !obj.$feature || store.getters['auth/features'] & obj.$feature

Resource.registerResource(User)