import Vue from 'vue'
import VueMeta from 'vue-meta'

import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueCurrencyInput from 'vue-currency-input'
import * as VueGoogleMaps from 'vue2-google-maps'

import { accessCheck, default as router } from './router';
import store from './store';
import i18n from './i18n'

import {} from './validators'

import './resources/Order'
import './resources/OrderLine'
import './resources/User'

import './filters'
import './printer'


import './css/main.scss'
import 'boxicons/css/boxicons.min.css'
import { modal } from './utils'

Vue.use(VueMeta)
Vue.use(BootstrapVue,{
  BModal: {
    okTitle: i18n.t('common.ok'),
    cancelTitle: i18n.t('common.cancel'),
    centered: true,
  },
  BOverlay: {
    zIndex: 1000,
  }
})

Vue.use(VueGoogleMaps, {
  load: {
    language: i18n.locale,
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places',
  },
})

Vue.use(VueCurrencyInput)

Vue.mixin({
  data() {
    return {
      isProduction: process.env.NODE_ENV === 'production'
    }
  },
  methods: {
    $modal: modal,
    $access: accessCheck
  }
})

Vue.component('v-select', vSelect)

Vue.config.productionTip = false

const app = new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
})

app.$mount('#app')

export default app